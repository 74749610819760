<template>
  <div>
    <v-row justify="space-around">
      <v-card class="pa-4 mt-2" width="1400" style="box-shadow: none">
        <v-img class="mx-auto" src="@/assets/investment-1.jpg" :aspect-ratio="3" max-width="1400"></v-img>
        <v-card-title>Willkommen im Nachhaltigkeitsresearch der Edekabank </v-card-title>

        <v-card-text class="text-justify">
          Willkommen im Nachhaltigkeitsresearch der Edekabank. Die Anwendung „Nachhaltigkeitsresearch“ bewertet
          Wertpapier-Emittenten unter Nachhaltigkeitsaspekten. Sie setzt damit die „Richtlinie für die nachhaltige
          Ausrichtung des Depot A“ um und stellt somit einen wesentlichen Baustein der Nachhaltigkeitsstrategie der
          Edekabank dar.
          <br />
          <br />
          Grundsätzlich gilt, dass nur bei positiver Bewertung der Nachhaltigkeit eines Emittenten der Erwerb eines
          Wertpapiers in Frage kommt. Dies zeigt die grüne Ampel in der Anwendung an. Neben verschiedenen
          Ausschlusskriterien werden die aktuelle Klimaschutzleistung in Form der Temperaturbeitrags, die
          Transformationsfähigkeit und auch der Transformationswille Positivkriterien betrachtet.
        </v-card-text>
        <v-row justify="end">
          <v-img src="@/assets/img.png" max-height="220" max-width="220" contain></v-img>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>
